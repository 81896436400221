import React, { useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import $ from "jquery";
import { Link } from "react-router-dom";

import { ReactComponent as Organizations } from "../../../../images/Organizations.svg";
import { ReactComponent as POS } from "../../../../images/POS.svg";
import { ReactComponent as Plus } from "../../../../images/Plus.svg";
import { ReactComponent as Revenue } from "../../../../images/Revenue.svg";
import { ReactComponent as History } from "../../../../images/History.svg";
import { ReactComponent as Beer } from "../../../../images/dispenser.svg";
import LanguageSwitcher from "../../../../components/LanguageSwitcher.jsx";

// import profile from "../../../../images/avatar.png";

import { Collapse } from "react-bootstrap";
import logo from "../../../../images/logo.png";
import miniLogo from "../../../../images/miniLogo.png";

function Sidebar({ organizations, setOrgId, orgId }) {
  const navigate = useNavigate();
  const { next } = useParams();
  const { t } = useTranslation("sidebar");
  useEffect(() => {
    sidebarFunctionality();
  }, []);

  const sidebarFunctionality = () => {
    /* eslint-disable */
    "use strict";
    $("#sidebarToggle, #sidebarToggleTop")
      .unbind()
      .on("click", function (e) {
        $("body").hasClass("sidebar-toggled")
          ? $("body").removeClass("sidebar-toggled")
          : $("body").addClass("sidebar-toggled"),
          $(".sidebar").hasClass("toggled")
            ? $(".sidebar").removeClass("toggled")
            : $(".sidebar").addClass("toggled"),
          $(".sidebar").hasClass("toggled") && $(".sidebar .collapse").hide();
      }),
      $(window).resize(function () {
        // $(window).width() < 768 && $(".sidebar .collapse").collapse("hide"),
        $(window).width() < 480 &&
          !$(".sidebar").hasClass("toggled") &&
          ($("body").addClass("sidebar-toggled"),
          $(".sidebar").addClass("toggled"),
          $(".sidebar .collapse").hide());
      }),
      $("body.fixed-nav .sidebar").on(
        "mousewheel DOMMouseScroll wheel",
        function (e) {
          var o;
          768 < $(window).width() &&
            ((o = (o = e.originalEvent).wheelDelta || -o.detail),
            (this.scrollTop += 30 * (o < 0 ? 1 : -1)),
            e.preventDefault());
        }
      ),
      $(document).on("scroll", function () {
        100 < $(this).scrollTop()
          ? $(".scroll-to-top").fadeIn()
          : $(".scroll-to-top").fadeOut();
      });

    $(window).width() < 576 &&
      !$(".sidebar").hasClass("toggled") &&
      ($("body").addClass("sidebar-toggled"),
      $(".sidebar").addClass("toggled"),
      $(".sidebar .collapse").hide());
  };

  const handleOrg = (id) => {
    if (orgId.includes(id)) {
      if (orgId.length > 1) {
        let tempId = [...orgId];
        var index = tempId.indexOf(id);
        if (index !== -1) {
          tempId.splice(index, 1);
          setOrgId(tempId);
        }
      }
    } else {
      setOrgId([...orgId, id]);
    }
  };

  const handlePosOrg = (id) => {
    setOrgId([id]);
  };

  // function Toggle(params) {
  //   const elements = window.document.querySelectorAll(".I234")
  //   console.log(elements);
  //   elements.forEach(element => {
  //     if (element.style.display === "none") {
  //       element.style.display = "block"
  //       window.document.querySelector(".sidebar").style.width = "17rem"
  //       window.document.getElementById("content-wrapper").style.marginLeft = "17rem"

  //     } else {
  //       element.style.display = "none"
  //       window.document.querySelector(".sidebar").style.width = "3rem"
  //       window.document.getElementById("content-wrapper").style.marginLeft = "6.5rem"

  //     }
  //   });
  // }

  return (
    <>
      {/* Sidebar */}
      <ul
        className="navbar-nav sidebar sidebar-dark accordion zi-1"
        id="accordionSidebar"
      >
        <li className="nav-item">
          {/* <button style={{ width: "10px" }} id="sidebarToggle" onClick={() => Toggle()}>Toggle</button> */}
          {/* Sidebar - Brand */}
          <Link
            className="sidebar-brand d-flex align-items-center justify-content-center mt-2"
            to="/dashboard/overview"
          >
            <div className="sidebar-brand-text ">
              <img src={logo} className="mr-2" alt="siepay" />
            </div>
            <div className="sidebar-brandSm">
              <img src={miniLogo} className="mr-2 img-fluid" alt="siepay" />
            </div>
          </Link>
          {/* <div className="mnu">Menu</div> */}
          {/* sidebar links */}

          {/* <li className="nav-item my-1  mt-5">
              <NavLink className="nav-link sidebar-Link fw-500" to="/dashboard/get-started">
                <GetStarted />
                <span className="I234">Get Started </span>
              </NavLink>
            </li> */}

          {/* Nav Item - Pages Collapse Menu */}
          {/* <li className="nav-item ">
              <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#POS" aria-expanded="true" aria-controls="POS">
                <POS />
                <span>POS</span>
              </a>
              <div id="POS" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className=" py-2 collapse-inner ">
                  <h6 className="collapse-header">Custom Components:</h6>
                  <a className="collapse-item" href="buttons.html">Buttons</a>
                  <a className="collapse-item" href="cards.html">Cards</a>
                </div>
              </div>
        </li>
        <li className="nav-item">
              <a className="nav-link collapsed" href="#" data-bs-toggle="collapse" data-bs-target="#History" aria-expanded="true" aria-controls="History">
                <HistoryIco />
                <span>History</span>
              </a>
              <div id="History" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                <div className=" py-2 collapse-inner ">
                  <h6 className="collapse-header">Custom Components:</h6>
                  <a className="collapse-item" href="buttons.html">Buttons</a>
                  <a className="collapse-item" href="cards.html">Cards</a>
                </div>
              </div>
        </li> */}
          <NavLink className="nav-link" to="/dashboard/overview">
            <Revenue />
            <span>{t("revenues")}</span>
            <i className="arrow down"></i>
          </NavLink>
          <Collapse in={next === "overview"}>
            <div>
              <div className="py-2 collapse-inner">
                {organizations.map((val, key) => (
                  <a
                    key={key}
                    className={
                      orgId.includes(val?.id)
                        ? "collapse-item d-flex ai-center active"
                        : "collapse-item d-flex ai-center"
                    }
                    href="#"
                    onClick={() => handleOrg(val?.id)}
                  >
                    <div className="activeBox">
                      <span></span>
                    </div>{" "}
                    {val?.organization}
                  </a>
                ))}
                {organizations.length === 0 && (
                  <a className="collapse-item">{t("no_cash_registers")}</a>
                )}
              </div>
            </div>
          </Collapse>
        </li>

        <li className="nav-item">
          <NavLink className="nav-link" to="/dashboard/history">
            <History />
            <span>{t("history")}</span>
            <i className="arrow down"></i>
          </NavLink>
          <Collapse in={next === "history"}>
            <div>
              <div className="py-2 collapse-inner">
                {organizations.map((val, key) => (
                  <a
                    key={key}
                    className={
                      orgId.includes(val?.id)
                        ? "collapse-item d-flex ai-center active"
                        : "collapse-item d-flex ai-center"
                    }
                    href="#"
                    onClick={() => handlePosOrg(val?.id)}
                  >
                    <div className="activeBox">
                      <span></span>
                    </div>{" "}
                    {val?.organization}
                  </a>
                ))}
                {organizations.length === 0 && (
                  <a className="collapse-item">{t("no_cash_registers")}</a>
                )}
              </div>
            </div>
          </Collapse>
        </li>

        <li className="nav-item">
          <NavLink className="nav-link" to="/dashboard/pos">
            <POS />
            <span>{t("configure_pos")}</span>
            <i className="arrow down"></i>
          </NavLink>
          <Collapse in={next === "pos"}>
            <div>
              <div className="py-2 collapse-inner">
                {organizations.map((val, key) => (
                  <a
                    key={key}
                    className={
                      orgId.includes(val?.id)
                        ? "collapse-item d-flex ai-center active"
                        : "collapse-item d-flex ai-center"
                    }
                    href="#"
                    onClick={() => handlePosOrg(val?.id)}
                  >
                    <div className="activeBox">
                      <span></span>
                    </div>{" "}
                    {val?.organization}
                  </a>
                ))}
                {organizations.length === 0 && (
                  <a className="collapse-item">{t("no_cash_registers")}</a>
                )}
              </div>
            </div>
          </Collapse>
        </li>

        <li className="nav-item">
          <NavLink className="nav-link" to="/dashboard/drinkwatch">
            <Beer />
            <span>{t("drinkwatch")}</span>
            <i className="arrow down"></i>
          </NavLink>
          <Collapse in={next === "drinkwatch"}>
            <div>
              <div className="py-2 collapse-inner">
                {organizations.map((val, key) => (
                  <a
                    key={key}
                    className={
                      orgId.includes(val?.id)
                        ? "collapse-item d-flex ai-center active"
                        : "collapse-item d-flex ai-center"
                    }
                    href="#"
                    onClick={() => handlePosOrg(val?.id)}
                  >
                    <div className="activeBox">
                      <span></span>
                    </div>{" "}
                    {val?.organization}
                  </a>
                ))}
                {organizations.length === 0 && (
                  <a className="collapse-item">{t("no_cash_registers")}</a>
                )}
              </div>
            </div>
          </Collapse>
        </li>

        <li className="nav-item">
          <NavLink className="nav-link" to="/dashboard/organizations">
            <Organizations />
            <span>{t("organization")}</span>
            <i className="arrow down"></i>
          </NavLink>
          <Collapse in={next === "organizations"}>
            <div>
              <div className="py-2 collapse-inner">
                {organizations.map((val, key) => (
                  <a
                    key={key}
                    className={
                      orgId.includes(val?.id)
                        ? "collapse-item d-flex ai-center active"
                        : "collapse-item d-flex ai-center"
                    }
                    href="#"
                    onClick={() => handlePosOrg(val?.id)}
                  >
                    <div className="activeBox">
                      <span></span>
                    </div>{" "}
                    {val?.organization}
                  </a>
                ))}
                <div className="ps-3 ms-1 mt-2">
                  <button
                    onClick={() => handlePosOrg()}
                    className="add-pos-btn"
                    type="button"
                  >
                    <Plus />
                    {t("add_pos")}
                  </button>
                </div>
              </div>
            </div>
          </Collapse>
        </li>

        <li className="nav-item mb-0 mt-auto">
          <div className="nav-link sidebar-Link fw-500 w-100 version" to="">
            <LanguageSwitcher />
          </div>
        </li>
      </ul>
      {/* End of Sidebar */}
    </>
  );
}
export default Sidebar;
