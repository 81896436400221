import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../firebase";

import { Dashboard } from "../dashboard/dashboard";
import Navbar from "../global/navbar/navbar";
import Sidebar from "../global/sidebar/sidebar";
import { OrganizationsPage } from "../organizations/OrganizationsPage";
import { POS } from "../pos/POS";
import { History } from "../history/History";
import { DrinkWatch } from "../drinkWatch/DrinkWatch";

function DashboardRouter() {
  const { next } = useParams();
  const userStore = useSelector((state) => state.userReducer);
  // const [startDate, setStartDate] = useState(new Date());
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [dateClickHandle, setDateClickHandle] = useState(false);
  const [dateResetHandle, setDateResetHandle] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");

  const [organizations, setOrganizations] = useState([]);
  const [snapShot, setSnapShot] = useState(null);

  const [orgId, setOrgId] = useState([]);
  const navigation = useNavigate();

  useEffect(() => {
    if (userStore.users?.uid) {
      getOrganizations();
    }
  }, [userStore.users, next]);

  useEffect(() => {
    const q = query(
      collection(db, "Organizations"),
      where("adminUid", "array-contains", userStore.users?.uid)
    );

    const unsuscribe = onSnapshot(q, (querySnapshot) => {
      setSnapShot(querySnapshot);
    });
    return () => {
      unsuscribe();
    };
  }, [userStore.users?.uid]);

  useEffect(() => {
    if (snapShot) {
      let records = [];

      snapShot.docs.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        // console.log(doc.id, " => ", doc.data());
        records.push(doc.data());
      });
      setOrganizations(records);
    }
  }, [snapShot]);

  const getOrganizations = async () => {
    let records = [];
    const q = query(
      collection(db, "Organizations"),
      where("adminUid", "array-contains", userStore.users?.uid)
    );
    // const q = query(collection(db, "Organizations"), where("adminUid", "array-contains", "UrEYrlKVbBSlmkDCMrNyUoVBMVI3"));
    // const q = query(collection(db, "Organizations"), where("adminUid", "array-contains", "ebdiuGbFADPHQsFV20ZhDL8WSt22"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      records.push(doc.data());
    });
    // console.log(records);
    setOrganizations(records);
    // setOrgId([...orgId, records[0].id]);
    let selectedOrg = [];
    for (const idx in records) {
      if (!selectedOrg.includes(records[idx].id)) {
        selectedOrg.push(records[idx].id);
      }
    }
    setOrgId([selectedOrg[0]]);
  };

  return (
    <div id="wrapper">
      <Sidebar
        organizations={organizations}
        setOrgId={setOrgId}
        orgId={orgId}
      />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Navbar
            setDateRange={setDateRange}
            startDate={startDate}
            endDate={endDate}
            setDateClickHandle={setDateClickHandle}
            dateClickHandle={dateClickHandle}
            setDateResetHandle={setDateResetHandle}
            dateResetHandle={dateResetHandle}
            setPaymentMethod={setPaymentMethod}
          />
          {next == "overview" && (
            <Dashboard
              organizations={organizations}
              setDateRange={setDateRange}
              orgId={orgId}
              dateClickHandle={dateClickHandle}
              startDate={startDate}
              endDate={endDate}
              dateResetHandle={dateResetHandle}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          )}
          {next == "history" && (
            <History
              organizations={organizations}
              setDateRange={setDateRange}
              orgId={orgId}
              dateClickHandle={dateClickHandle}
              startDate={startDate}
              endDate={endDate}
              dateResetHandle={dateResetHandle}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          )}
          {next == "pos" && <POS organizations={organizations} orgId={orgId} />}
          {/* {next == "drinkwatch" && (
            <DrinkWatch
              organizations={organizations}
              setDateRange={setDateRange}
              orgId={orgId}
              dateClickHandle={dateClickHandle}
              startDate={startDate}
              endDate={endDate}
              dateResetHandle={dateResetHandle}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          )} */}
          {next == "organizations" && (
            <OrganizationsPage organizations={organizations} orgId={orgId} />
          )}
          {/* <Routes>
                  
                    <Route exact path='/dashboard/overview' element={<Dashboard/>} />
                </Routes> */}
        </div>
      </div>
    </div>
  );
}

export default DashboardRouter;
