export const Icons = {
  default: require("./organization2.png"),
  gallery: require("./gallery.png"),
  CloseGrayCircle: require("./close.jpg"),
  camera: require("./camera.png"),
  clothes: require("./clothes.png"),
  dispenser: require("./dispenser.png"),
  food: require("./food.png"),
  cocktail: require("./cocktail.png"),
  drink: require("./drink.png"),
  soft: require("./soft.png"),
  beer: require("./beer.png"),
  add: require("./add.png"),
  can: require("./can.png"),
  bottle: require("./bottle.png"),
  vine: require("./vine.png"),
  delete: require("./dlt.png"),
  all: require("./all.png"),
  arrow: require("./arrow.png"),
  close: require("./close.jpg"),
  wine: require("./wine.png"),
  others: require("./others.png"),
  getStarted: require("./getStarted.png"),
  Logo: require("./Logo_portrait.png"),
  Organization: require("./organization2.png"),
  participants: require("./participants.png"),
  up: require("./up.png"),
  below: require("./below.png"),
  empty: require("./empty.png"),
  ticketEmpty: require("./ticketEmpty.png"),
  paid: require("./paid.png"),
  notPaid: require("./not_paid.png"),
  basket: require("./basket.png"),
  cash: require("./cash.png"),
  webshop: require("./webshop.png"),
  gobletes: require("./gobletes.png"),
  scanOk: require("./scan_ok.png"),
  scanNotOk: require("./scan_not_ok.png"),
  cup: require("./cup.png"),
  insurance: require("./insurance.png"),
  pos: require("./pos.png"),
  wc: require("./wc.png"),
  pro: require("./comitty_pro.png"),
  card: require("./card.png"),
  scan: require("./scan.png"),
};

export const Beers = {
  blond: require("./beers/blond.png"),
  bottle: require("./beers/bottle.png"),
  bottle1: require("./beers/bottle1.png"),
  bottle2: require("./beers/bottle2.png"),
  brown: require("./beers/brown.png"),
  can: require("./beers/can.png"),
  jug: require("./beers/jug.png"),
  mug: require("./beers/mug.png"),
  pils: require("./beers/pils.png"),
  pint: require("./beers/pint.png"),
  tap: require("./beers/tap.png"),
  special: require("./beers/special.png"),
  cherry: require("./beers/cherry.png"),
  box: require("./beers/beer-box.png"),
  glass: require("./beers/beer-glass.png"),
  pong: require("./beers/beer-pong.png"),
  tray: require("./beers/tray.png"),
};

export const Softs = {
  chocolateMilk: require("./softs/chocolate-milk.png"),
  cola: require("./softs/cola.png"),
  colaCan: require("./softs/colaCan.png"),
  energyDrink: require("./softs/energy-drink.png"),
  icedTea: require("./softs/iced-tea.png"),
  juice: require("./softs/juice.png"),
  lemon: require("./softs/lemon.png"),
  lemonCan: require("./softs/lemonCan.png"),
  orange: require("./softs/orange.png"),
  orangeCan: require("./softs/orangeCan.png"),
  water: require("./softs/water.png"),
  sparkling: require("./softs/sparkling-water.png"),
  drink: require("./softs/drink.png"),
};

export const Food = {
  burger: require("./food/burger.png"),
  cheese: require("./food/cheese.png"),
  chips: require("./food/chips.png"),
  cottonCandy: require("./food/cotton-candy.png"),
  fries: require("./food/fries.png"),
  hotdog: require("./food/hotdog.png"),
  iceCream: require("./food/ice-cream.png"),
  noodles: require("./food/noodles.png"),
  pizza: require("./food/pizza.png"),
  popCorn: require("./food/popcorn.png"),
  potatoChips: require("./food/potato-chips.png"),
  sausage: require("./food/sausage.png"),
  sweets: require("./food/sweets.png"),
  meatballs: require("./food/meatballs.png"),
  barbecueSausage: require("./food/barbecue_sausage.png"),
  bread: require("./food/bread.png"),
  friedChicken: require("./food/fried-chicken.png"),
  sandwich: require("./food/sandwich.png"),
  sauce: require("./food/sauce.png"),
  pasta: require("./food/spaguetti.png"),
  steak: require("./food/steak.png"),
  chicken: require("./food/chicken-leg.png"),
};

export const Cocktails = {
  blueCocktail: require("./cocktails/blue-cocktail.png"),
  cubaLibre: require("./cocktails/cuba-libre.png"),
  gin: require("./cocktails/gin.png"),
  margarita: require("./cocktails/margarita.png"),
  mojito: require("./cocktails/mojito.png"),
  pinkCocktail: require("./cocktails/pink-cocktail.png"),
  redCocktail: require("./cocktails/red-cocktail.png"),
  yellowCocktail: require("./cocktails/yellow-cocktail.png"),
  pastis: require("./cocktails/pastis.png"),
};

export const Wine = {
  champagne: require("./wine/champagne.png"),
  pinkBottle: require("./wine/pink-bottle.png"),
  pinkGlass: require("./wine/pink-glass.png"),
  redBottle: require("./wine/red-bottle.png"),
  redGlass: require("./wine/red-glass.png"),
  whiteBottle: require("./wine/white-bottle.png"),
  whiteGlass: require("./wine/white-glass.png"),
};

export const HotDrinks = {
  coffee: require("./hotdrinks/coffee-cup.png"),
  hotChoco: require("./hotdrinks/hot-choco.png"),
  hotWine: require("./hotdrinks/hot-wine.png"),
  soup: require("./hotdrinks/soup.png"),
  tea: require("./hotdrinks/tea.png"),
};

export const Others = {
  bands: require("./others/bands.png"),
  beerPong: require("./others/beer-pong.png"),
  bracelet: require("./others/bracelet.png"),
  hammering: require("./others/hammering.png"),
  cards: require("./others/playing-cards.png"),
  ticket: require("./others/ticket.png"),
  shirt: require('./others/shirt.png'),
  hat: require('./others/cap.png'),
};
